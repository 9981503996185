
// base.scss
@mixin body-font {
  font-family: "Roboto", sans-serif;
}


/* base color */
$base-color: #636363;
$main-color: #f4b350;
$color-main-text: #555555;

/* used color */
$color-red: #b70015;
$color-light-red: #be142f;
$color-white: #ffffff;
$color-very-light-grey: #f5f5f5;
$color-light-grey: #ebebeb;
$color-grey: #a5a5a5;
$color-dark-grey: #636363;
$color-yellow: #f4b350;
$color-blue: #193c8d;
