
// themes.scss

// -----------------------------------------------------------------------------
// Common class
// -----------------------------------------------------------------------------
.helpful-fact {
	width: 35%;
	margin: 0 auto 40px;

	@media(max-width: 767px){
		width: 80%;

		ul {
			padding-left: 15px;
		}
	}

	h4 {
		position: relative;
		padding-top: 30px;
		color: #b70015;
		padding-bottom: 20px;
		&:before {
			content: "";
			position: absolute;
			top: 0;
			right: calc(50% - 35px);
			height: 3px;
			width: 75px;
			background: #ebebeb;
		}
	}
}
body {
	&.page-template-template-deals,
	&.page-template-template-facilities,
	&.page-template-contact-page {
		> #wrapper {
			// margin-top: 110px;
		}
	}
	> #wrapper {
		// margin-top: 55px;
	}
}

.verticent {
	display: table;
	height: 100%;
	width: 100%;
	table-layout: fixed;
	.verticent-inner {
		text-align: center;
		margin: 0;
		padding: 10px 0;

		@media(max-width: 1024px){
			text-align: center;
		}
		@media(max-width: 767px) {
			padding: 10px 5px;
			> span {
				display: block;
				text-align: center;
			}
		}
	}
}
a {
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	transition: all 0.5s ease;
}
h1, h3 {
	&.heading-title {
		color: $main-color;
		text-align: center;
		text-transform: uppercase;
		font-size: 24px;
		margin-bottom: 40px;
		position: relative;
		font-weight: 500;
	}
}

.double-option {
	width: 80%;
	margin: 0 auto;
	text-align: center;
	@media(max-width: 767px){
		width: 90%;
	}
	a {
		width: 25%;
		@media(max-width: 767px){
			width: 40%;
		}
	}
	span {
		width: 5%;
		display: inline-block;
		@media(max-width: 767px){
			width: 10%;
		}
	}
}

.box-schedule {
	ul {
		list-style: none;
		padding-left: 0;

		li {
			@media(min-width: 768px) and (max-width: 1024px){
				display: inline-block;
				margin-bottom: 10px;
			}
			@media(max-width: 767px){
				display: inline-block;
			}
			span {
				float: left;
			}
			.scleft {
				min-width: 100px;
				@media(min-width: 768px) and (max-width: 1024px){
					font-weight: 600;
				}
			}
			.dot {
				padding: 0 10px;
				@media(min-width: 768px) and (max-width: 1024px){
					display: none;
				}
			}
			.scright {
				width: 160px;
			}
		}
	}
}

.slider-nav {
  &.global-thumbimg {
    margin: 0 -10px;

    .item {
      padding: 0 10px;
    }
  }
}

.global-centerimg, .global-thumbimg {
  a, span {
    display: block;
    padding-bottom: 75%;
    height: 0;
    overflow: hidden;
    position: relative;
    width: 100%;

    img {
      left: 50%;
      margin: 0 auto !important;
      position: absolute;
      height: 100%;
      width: auto;
      transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
    }
  }
}
@media (min-width: 1025px) {
	.pll-parent-menu-item {
		display: none!important;
	}
}

// -----------------------------------------------------------------------------
// Top section
// -----------------------------------------------------------------------------
.section-top {
	position: relative;
	@media (max-width: 360px) {

	}
	.login-section {
		height: 25px;
		overflow: hidden;
		background: $color-white;
		-webkit-transition: all 0.3s ease;
		-ms-transition: all 0.3s ease;
		transition: all 0.3s ease;
		span {
			display: inline-block;
			height: 25px;
			line-height: 25px;
			&:nth-of-type(2) {
				margin: 0 10px;
				height: 25px;
				line-height: 25px;
			}
			a {
				color: $color-dark-grey;
				&:hover {
					color: $main-color;
				}
			}
		}
	}
	.navbar-default {
		-webkit-transition: all 0.5s ease;
		-ms-transition: all 0.5s ease;
		transition: all 0.5s ease;
		.top-logo {
			a.logo-white {
				display: block;
			}
			a.logo-black {
				display: none;
			}
		}
	}
	&.scrolled {
		.login-section {
			height: 0;
		}
		.navbar-default {
			background: $color-white;
			border-bottom: 3px solid $main-color;
			.nav {
				> li {
					> a {
						color: $color-dark-grey;
						&:hover {
							color: $main-color;
						}
						&:focus {
							color: $main-color;
						}
					}
				}
			}
			.top-logo {
				a.logo-white {
					display: none;
				}
				a.logo-black {
					display: block;
				}
			}
		}
	}
}
.top-logo {
	a {
		padding: 15px 0;
		display: inline-block;
		img {
			height: 30px;
			// -webkit-transition: all 0.3s;
			// -o-transition: all 0.3s;
			// transition: all 0.3s;
			// @media (max-width: 360px) {
			// 	height: 20px;
			// }
		}
	}
}
// adjust widget on header
.cdsROW.wide {
	height: auto !important;
	width: auto !important;
}
.cdsROW.wide .cdsROWContainer {
	height: auto !important;
	padding-top: 22px;
	display: block !important;
	@media (max-width: 767px) {
		padding-top: 15px;
	}
	@media (max-width: 360px) {
		padding-top: 2px;
	}
}
.cdsROW.wide .logo {
	border-right: transparent !important;
	padding-right: 5px !important;
}
.cdsROW.wide .cdsROWContainer .cdsComponent {
	@media (max-width: 767px) {
		display: inherit !important;
	}
}
.cdsROW.wide .cdsLocName {
	display: none !important;
}
.cdsROW.wide .cdsRating {
	padding-right: 0 !important;
}
.cdsROW .cdsRating .ui_bubble_rating {
	font-size: 10px !important;
}
.cdsROW .logo img {
	width: 105px !important;
}
.TA_links {
	display: none !important;
}
// end widget on header

.section-top {
	// -webkit-transition: all 0.3s;
	// 	  -o-transition: all 0.3s;
	// 	  transition: all 0.3s;
	// &.scrolled {
	// 	min-height: 70px;
	// 	line-height: 70px;
	// 	// -webkit-transition: all 0.3s;
	// 	//   -o-transition: all 0.3s;
	// 	//   transition: all 0.3s;
	// 	.top-logo {
	// 		img {
	// 			height: 70px;
	// 			// -webkit-transition: all 0.3s;
	// 			// -o-transition: all 0.3s;
	// 			// transition: all 0.3s;
	// 		}
	// 	}
	// }
}

// -----------------------------------------------------------------------------
// Homepage
// -----------------------------------------------------------------------------
#wrapper {
	// margin-bottom: 40px;
}
.bottom-section-white {
	background: $color-white;
	padding-top: 50px;
	@media (max-width: 767px) {
		padding-top: 20px;
	}
}
.our-team {
	&.single {
		margin-top: 30px;
		.right-team {
			width: 100% !important;
			.staff {
				.outer-staff {
					width: 25% !important;
					padding-bottom: 25% !important;
					@media (max-width: 767px) {
						width: 50% !important;
						padding-bottom: 50% !important;
					}
				}
			}
		}
	}
	.grid-our-team {
		&:after {
			content: '';
			display: table;
			clear: both;
		}
		.left-title {
			position: relative;
			overflow: hidden;
			padding-bottom: 50%;
			width: 50%;
			float: left;
			@media (max-width: 1024px) {
				width: 100%;
			}
			@media (max-width: 767px) {
				padding-bottom: 60%;
			}
			&:hover {
				.overlay {
					opacity: 1;
					a {
						max-height: 100%;
						max-width: 100%;
						font-size: 34px;
					}
				}
			}
			.overlay {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background: rgba(0,0,0,0.7);
				z-index: 5;
				opacity: 0;
				-webkit-transition: all .5s ease;
				-ms-transition: all .5s ease;
				transition: all .5s ease;
				@media (max-width: 1024px) {
					opacity: 1;
				}
				a {
					max-height: 0;
					max-width: 0;
					border: 2px solid $main-color;
					color: $color-white;
					font-size: 0px;
					padding: 20px 30px;
					font-weight: 200;
					overflow: hidden;
					text-align: center;
					text-transform: uppercase;
					z-index: 10;
					display: block;
					position: absolute;
					left: 50%;
					top: 50%;
					-webkit-transform: translate(-50%, -50%);
					-ms-transform: translate(-50%, -50%);
					transform: translate(-50%, -50%);
					@media (max-width: 1024px) {
						max-height: 100%;
						max-width: 100%;
						font-size: 34px;
					}
					span {
						display: block;
						&:first-child {
							letter-spacing: 10px;
						}
					}
				}
			}
			img {
				height: 100%;
				width: auto;
				position: absolute;
				left: 50%;
				top: 50%;
				-webkit-transform: translate(-50%, -50%);
				-ms-transform: translate(-50%, -50%);
				transform: translate(-50%, -50%);
				@media (max-width: 1024px) {
					height: auto;
					width: 100%;
				}
			}
		}
		.right-team {
			width: 50%;
			float: left;
			@media (max-width: 1024px) {
				width: 100%;
			}
			.staff {
				.outer-staff {
					position: relative;
					overflow: hidden;
					padding-bottom: 50%;
					width: 50%;
					float: left;
					.overlay {
						position: absolute;
						left: 0;
						top: 0;
						width: 100%;
						height: 100%;
						background: rgba(0,0,0,0.7);
						z-index: 10;
						opacity: 0;
						-webkit-transition: all .5s ease;
						-ms-transition: all .5s ease;
						transition: all .5s ease;
						.staff-desc {
							color: $color-white;
							position: absolute;
							bottom: 15px;
							z-index: 15;
							left: -100%;
							-webkit-transition: all .5s ease;
							-ms-transition: all .5s ease;
							transition: all .5s ease;
							.name {
								font-size: 16px;
								font-weight: 500;
							}
							.position {
								color: $main-color;
								margin-bottom: 5px;
								position: relative;
								&:before {
									content: '';
									display: block;
									position: absolute;
									background: $main-color;
									height: 1px;
									width: 50px;
									left: -55px;
									top: 45%;
								}
							}
							.social {
								a {
									display: inline-block;
									background: $color-white;
									color: $main-color;
									border-radius: 100%;
									height: 20px;
									width: 20px;
									text-align: center;
									line-height: 20px;
									&:hover {
										background: $main-color;
										color: $color-white;
									}
								}
							}
						}
					}
					&:hover {
						.overlay {
							opacity: 1;
							.staff-desc {
								left: 15px;
							}
						}
					}
					img {
						height: 100%;
						width: auto;
						position: absolute;
						left: 50%;
						top: 50%;
						-webkit-transform: translate(-50%, -50%);
						-ms-transform: translate(-50%, -50%);
						transform: translate(-50%, -50%);
						z-index: 5;
					}
				}
			}
		}
	}
}
.homepage {
	.section {
		margin-bottom: 40px;
		@media (max-width: 767px) {
			margin-bottom: 15px;
		}
		&:after {
			content: '';
			display: table;
			clear: both;
		}
		> h2 {
			font-size: 26px;
			margin-bottom: 40px;
			color: $color-blue;
			text-align: center;
			text-transform: capitalize;
			@media (max-width: 767px) {
				margin-bottom: 15px;
				margin-top: 30px;
			}
		}
		h1, h3 {
			color: $main-color;
			text-align: center;
			text-transform: uppercase;
			font-weight: 500;
			margin-bottom: 25px;
		}
		&.main-service {
			.row-list {
				margin: 0 -2.5px;
				.list {
					float: left;
					width: 25%;
					padding: 2.5px;
					@media (max-width: 767px) {
						width: 100%;
					}
					.outer-service {
						background: $color-very-light-grey;
						padding: 10px;
						.img-service {
							text-align: center;
							height: 40px;
							position: relative;
							margin-top: 15px;
							img {
								position: absolute;
								max-height: 100%;
								max-width: 90%;
								display: block;
								left: 50%;
								top: 50%;
								-webkit-transform: translate(-50%, -50%);
								-ms-transform: translate(-50%, -50%);
								-o-transform: translate(-50%, -50%);
								transform: translate(-50%, -50%);
							}
						}
						.title-service {
							position: relative;
							height: 1px;
							margin: 15px 0 10px 0;
							&:after {
								content: '';
								position: absolute;
								display: block;
								width: 75px;
								height: 1px;
								background: $color-grey;
								bottom: 0;
								left: 50%;
								-webkit-transform: translate(-50%, 0%);
								-ms-transform: translate(-50%, 0%);
								transform: translate(-50%, 0%);
							}
						}
						.content-service {
							p {
								height: 51px;
								overflow: hidden;
								@media (max-width: 767px) {
									height: 35px;
								}
							}
							> a {
								text-transform: uppercase;
								display: inline-block;
								color: $main-color;
								&:hover {
									color: $color-blue;
								}
							}
						}
					}
				}
			}
		}

		&.top-subscription, &.bottom-subscription {
			.row {
				> div {
					@media (max-width: 767px) {
						padding: 0;
					}
					.right-form {
						@media (max-width: 767px) {
							background: $color-blue;
							padding: 0 -15px;
						}
					}
				}
			}
			background: $color-blue;
			.left-image {
				height: 150px;
				background-size: cover;
				background-position: center;
				@media (min-width: 1024px) {
					margin-right: -15px;
				}
			}
			.right-form {
				text-align: center;
				padding: 15px 15px 15px 0;
				@media (max-width: 1023px) {
					padding: 15px;
				}
				label {
					color: $color-white;
					font-size: 16px;
					display: block;
					margin-bottom: 10px;
					font-weight: normal;
				}
				input[type="text"] {
					margin-bottom: 15px;
					text-align: center;
					border-radius: 0;
				}
				.button {
					button {
						border-radius: 0;
						background: $main-color;
						&:hover {
							color: $color-white;
						}
					}
				}
			}
		}
		&.bottom-subscription {
			.right-form {
				padding: 15px;

				label, input[type="text"] {
					max-width: 700px;
					margin-left: auto;
					margin-right: auto;
				}
			}
		}
		&.main-slider {
			position: relative;
		}
		&.smart-system {
			.right-content {
				@media (max-width: 767px) {
					margin-top: 15px;
				}
			}
			ul {
				list-style-type: none;
				margin: 0;
				padding: 0;
				li {
					// position: relative;
					// padding: 0 15px;
					// margin-bottom: 15px;
					font-size: 14px;
					&:before {
					// 	content: '';
					// 	position: absolute;
					// 	left: 0;
						top: -2px;
					// 	height: 8px;
					// 	width: 8px;
					// 	background: $main-color;
					// 	-webkit-border-radius: 100%;
					// 	-ms-border-radius: 100%;
					// 	border-radius: 100%;
					}
				}
			}
		}
		&.content-portfolio {
			.outer-random-portfolio {
				.portfolio-list {
					float: left;
					width: 50%;
					padding-bottom: 35%;
					position: relative;
					overflow: hidden;
					@media (max-width: 767px) {
						width: 100%;
						float: none;
						padding-bottom: 45%;
						margin-bottom: 5px;
					}
					&:hover {
						.overlay {
							opacity: 1;
						}
					}
					.overlay {
						position: absolute;
						left: 0;
						top: 0;
						width: 100%;
						height: 100%;
						background: rgba(0,0,0,0.7);
						z-index: 5;
						opacity: 0;
						-webkit-transition: all .5s ease;
						-ms-transition: all .5s ease;
						transition: all .5s ease;
						@media (max-width: 1024px) {
							opacity: 1;
						}
						.desc {
							color: $color-white;
							font-weight: 200;
							overflow: hidden;
							text-align: center;
							text-transform: uppercase;
							z-index: 10;
							display: block;
							position: absolute;
							left: 50%;
							top: 50%;
							-webkit-transform: translate(-50%, -50%);
							-ms-transform: translate(-50%, -50%);
							transform: translate(-50%, -50%);
							h2 {
								font-size: 18px;
								font-family: 'Roboto', sans-serif, Arial;
								font-weight: 200 !important;
								margin-bottom: 0;
								a {
									padding: 20px 30px;
									display: block;
									border: 2px solid $main-color;
									&:hover {
										background: $main-color;
									}
								}
							}
							a {
								color: $color-white;
							}
						}
					}
					img {
						position: absolute;
						height: 100%;
						width: auto;
						top: 50%;
						left: 50%;
						-webkit-transform: translate(-50%, -50%);
						-ms-transform: translate(-50%, -50%);
						transform: translate(-50%, -50%);
						@media (max-width: 767px) {
							width: 100%;
							height: auto;
						}
					}
				}
			}
		}
		&.content-awards {
			@media(max-width: 767px){
				margin-bottom: 0;
			}
			.awards-slide {
				width: 80%;
				margin: 0 auto;
			}

			.awardimg {
				width: 35%;

				@media(max-width: 992px) {
					width: auto;
					display: block;
					float: none !important;
				}

				img {
					width: 65%;
					margin: 0 auto;

					@media(max-width: 992px) {
						max-width: 100%;
						display: block;
						float: none !important;
					}
				}
			}
			.awardtext {
				width: 65%;
				padding: 55px 0;

				@media(max-width: 992px) {
					width: auto;
					display: block;
					float: none !important;
					text-align: center;
				}

				@media(max-width: 767px){
					padding: 10px 0;
				}

				p {
					font-size: 20px;
				}
			}
			.awards-slide {
				position: relative;
			}
		}
		&.content-rooms {
			.slideroom-nav {
				@media(max-width: 767px){
					display: none;
				}
				.item {
					&:hover, &.slick-current {
						cursor: pointer;
						&:focus {
							outline: 0;
						}
						.thumb-room {
							background: $color-light-grey;
						}
					}
				}
				.thumb-room {
					margin-bottom: 5px;
				}
				.room-img {
					width: 30%;
					float: left;
				}
				.room-name {
					width: 65%;
					float: left;
					padding: 7px 15px;
					text-transform: uppercase;
					h4 {
						color: $main-color;
						margin-bottom: 5px;
						margin-top: 10px;
					}
				}
			}
		}
	}
}
// porfolio single
.content-portfolio {
	.outer-random-portfolio {
		.portfolio-list {
			float: left;
			width: 50%;
			padding-bottom: 35%;
			position: relative;
			overflow: hidden;
			@media (max-width: 767px) {
				width: 100%;
				float: none;
				padding-bottom: 40%;
				margin-bottom: 5px;
			}
			&:hover {
				.overlay {
					opacity: 1;
				}
			}
			.overlay {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background: rgba(0,0,0,0.7);
				z-index: 5;
				opacity: 0;
				-webkit-transition: all .5s ease;
				-ms-transition: all .5s ease;
				transition: all .5s ease;
				@media (max-width: 1024px) {
					opacity: 1;
				}
				.desc {
					color: $color-white;
					font-weight: 200;
					overflow: hidden;
					text-align: center;
					text-transform: uppercase;
					z-index: 10;
					display: block;
					position: absolute;
					left: 50%;
					top: 50%;
					-webkit-transform: translate(-50%, -50%);
					-ms-transform: translate(-50%, -50%);
					transform: translate(-50%, -50%);
					h2 {
						font-size: 18px;
						font-family: 'Roboto', sans-serif, Arial;
						font-weight: 200 !important;
						margin-bottom: 0 !important;
						a {
							padding: 20px 30px;
							display: block;
							border: 2px solid $main-color;
							&:hover {
								background: $main-color;
							}
						}
					}
					a {
						color: $color-white;
					}
				}
			}
			img {
				position: absolute;
				height: 100%;
				width: auto;
				top: 50%;
				left: 50%;
				-webkit-transform: translate(-50%, -50%);
				-ms-transform: translate(-50%, -50%);
				transform: translate(-50%, -50%);
				@media (max-width: 767px) {
					width: 100%;
					height: auto;
				}
			}
		}
	}
}

// Package page
.outer-package-page {
	margin-bottom: 50px;
	.logo {
		width: 100%;
		max-width: 300px;
		padding: 0 15px;
		margin: 30px auto;
		img {
			width: 100%;
		}
	}
	> h3 {
		text-align: center;
		margin: 10px 15px;
		color: $color-blue;
	}
	> p {
		text-align: center;
		width: 100%;
		max-width: 600px;
		padding: 0 15px;
		margin: 20px auto;
		color: $color-grey;
	}
	.outer-list-package {
		margin-bottom: 30px;
		.list-package {
			float: left;
			width: 33.333%;
			@media (max-width: 767px) {
				width: 100%;
			}
			&:nth-of-type(2) {
				.border {
					margin: 0 -1px;
					@media (max-width: 767px) {
						margin: 0;
					}
				}
			}
			.border {
				border: 1px solid $color-grey;
				padding: 20px 30px;
				position: relative;
				-webkit-transition: all 0.3s ease;
				-ms-transition: all 0.3s ease;
				-o-transition: all 0.3s ease;
				transition: all 0.3s ease;
				&:before {
					content: '';
					position: absolute;
					left: 0;
					top: 0;
					display: block;
					width: 100%;
					height: 5px;
					background: $color-grey;
					-webkit-transition: all 0.3s ease;
					-ms-transition: all 0.3s ease;
					-o-transition: all 0.3s ease;
					transition: all 0.3s ease;
				}
				&:hover {
					background: $color-very-light-grey;
					&:before {
						background: $main-color;
					}
				}
				.title {
					h4 {
						text-align: center;
						padding-bottom: 10px;
						border-bottom: 1px solid $color-grey;
						line-height: 1.3;
						font-family: "Roboto", sans-serif;
						font-weight: 400 !important;
						color: $color-dark-grey;
						font-size: 16px;
						height: 50px;
						overflow: hidden;
						@media (min-width: 768px) and (max-width: 992px) {
							height: 70px;
						}
					}
				}
				.price {
					h4 {
						text-align: center;
						color: $main-color;
						font-size: 24px;
						margin: 20px 0;
					}
				}
				.desc-list {
					height: 125px;
					overflow: hidden;
					color: $color-grey;
				}
				.select-button {
					text-align: center;
					padding: 20px 0;
					a {
						display: block;
						text-align: center;
						padding: 7px 10px;
						color: $color-white;
						background: $main-color;
						text-transform: uppercase;
						font-weight: 500;
						font-size: 14px;
						letter-spacing: 1px;
					}
				}
				.method {
					text-align: center;
					padding: 5px 30px;
					background: $color-white;
					border-top: 1px solid $color-light-grey;
					margin: 0 -30px -20px -30px;
					@media (min-width: 768px) and (max-width: 992px) {
						height: 45px;
					}
				}
			}
		}
	}
	#tabspackage {
		.nav-tabs {
	    border-bottom: 0 solid transparent;
			> li {
				width: 50%;
				&:first-child {
					a {
						border-right: 0;
					}
				}
				&:last-child {
					a {
						border-left: 0;
					}
				}
				&.active {
					> a {
						border: 1px solid $main-color;
						&:before {
							background: $main-color;
							border: 1px solid $main-color;
						}
					}
				}
				> a {
					margin-right: 0;
					border-radius: 0;
					border: 1px solid $color-grey;
					padding: 10px 15px 10px 30px;
					text-align: center;
					font-size: 16px;
					color: $color-dark-grey;
					font-weight: 500;
					position: relative;
					@media (max-width: 767px) {
						font-size: 13px;
						text-align: left;
						padding: 5px 10px 5px 30px;
						height: 44px;
						position: relative;
					}
					@media (max-width: 767px) {
						span {
							line-height: 1.2;
							display: block;
							position: absolute;
							left: auto;
							top: 50%;
							-webkit-transform: translate(0, -50%);
							-ms-transform: translate(0, -50%);
							-o-transform: translate(0, -50%);
							transform: translate(0, -50%);
						}
					}
					&:hover {
						background: $color-very-light-grey;
					}
					&:before {
						content: '';
						display: block;
						position: absolute;
						left: 10px;
						top: 50%;
						-webkit-transform: translate(0, -50%);
						-ms-transform: translate(0, -50%);
						-o-transform: translate(0, -50%);
						transform: translate(0, -50%);
						height: 12px;
						width: 12px;
						border: 1px solid $color-grey;
						border-radius: 100%;
					}
				}
			}
		}
	}
	.tab-content {
		border: 1px solid $main-color;
		padding: 5px;
		margin-bottom: 30px;
		table {
			width: 100%;
			td {
				padding: 5px 10px;
			}
			.bg-orange {
				background: $main-color;
				color: $color-white;
				text-align: center;
			}
			.bg-grey {
				background: $color-dark-grey;
				color: $color-white;
				text-align: center;
			}
			.bg-light-grey {
				background: $color-light-grey;
				text-align: center;
			}
		}
		table, tr, td {
			border-collapse: collapse;
			border: 2px solid $color-white;
		}
		.payment-gateway-fee {
			text-align: center;
			text-transform: uppercase;
			padding: 5px 15px;
			margin: 0 -5px -5px;
		}
	}
}
.outer-form-information {
	border-top: 2px solid $color-light-grey;
	padding-top: 30px;
	margin-top: 40px;
	@media (min-width: 768px) {
	padding-left: 50px;
	padding-right: 50px;
	}

	h3 {
		text-align: center;
		margin-bottom: 30px;
	}
	label {
		font-weight: 500;
	}
	.form-control {
		border-radius: 0;
		-webkit-box-shadow: none;
		box-shadow: none;
	}
	.checkbox {
		margin-top: 20px;
		input[type=checkbox] {
			margin: 2px 0 0 -20px;
		}
	}
	.button-area {
		.btn {
			border-radius: 0;
			background: $main-color;
			color: $color-white;
			width: 200px;
			font-weight: 500;
			letter-spacing: 1px;
			border: 0 transparent;
			font-weight: 16px;
		}
		.already {
			margin-top: 15px;
		}
	}
}

// -----------------------------------------------------------------------------
// Pages
// -----------------------------------------------------------------------------


	.content-rooms, .content-resto, .content-gallery, .content-facility, .content-meeting, .content-deals, .content-beauty {
		.box-content {
			border: 1px solid $color-light-grey;
			margin-bottom: 30px;
			-webkit-transition: all 0.5s ease;
			-moz-transition: all 0.5s ease;
			-ms-transition: all 0.5s ease;
			transition: all 0.5s ease;
			&:hover {
				border: 1px solid $color-grey;
			}

			.box-image {
				margin-bottom: 5px;
				a, span {
					position: relative;
			    height: 0;
			    padding-bottom: 58.34%;
			    overflow: hidden;
			    display: block;
					img {
						@include imgbase;
					}
				}
			}

			.box-info {
				padding: 15px;

				> img {
					margin: 0 auto 20px;
					height: 55px;
				}

				.desc {
					p {
						height: 100px;
						overflow: hidden;
						min-height: 85px;
					}
				}

				h4 {
					text-transform: uppercase;
					font-weight: 400;
					font-size: 16px;
					color: $main-color;
					position: relative;
					padding-bottom: 20px;
					margin-bottom: 20px;
					&:after {
						content: '';
						position: absolute;
						bottom: 0;
						right: calc(50% - 35px);
						left: calc(50% - 35px);
						height: 1px;
						width: 75px;
						background: $color-light-grey
					}
				}
				h2 {
					font-size: 22px;
					text-transform: uppercase;
					color: $main-color;
					padding-bottom: 20px;
					margin-bottom: 20px;
					position: relative;
					&:after {
						content: '';
						position: absolute;
						bottom: 0;
						right: calc(50% - 35px);
						left: calc(50% - 35px);
						height: 1px;
						width: 75px;
						background: $color-light-grey
					}
				}

				.subtitle {
					font-size: 16px;
					text-transform: uppercase;
				}

				.info-detail {
					list-style: none;
					padding-left: 0;
					//width: 80%;
					margin: 0 auto;
					text-align: left;
					margin-bottom: 20px;

					li {
						span {
							display: inline-block;
						}
						.detail-left {
							min-width: 85px;
							@media(max-width: 767px) {
								min-width: 0;
								width: 75px;
							}
						}
						.dot {
							padding: 0 5px;
							@media(max-width: 767px) {
								padding: 0 3px;
							}
						}
					}
				}
			}
		}
	}
	.page {
	.main-slider {
		margin-bottom: 30px;

		&.slider-resto {
			.owl-carousel {
				.owl-slide {
					padding-bottom: 80vh;
					background-position: 50% 30%;
				}
			}
		}
		&.slider-room, &.slider-gallery, &.slider-facilities, &.slider-beauty, &.slider-meeting, &.slider-deals {
			.owl-carousel {
				.owl-slide {
					padding-bottom: 80vh;
					background-position: 50% 50%;
				}
				.owl-nav {
					top: 50%;
				}
			}
		}
	}
	.content-resto {
		&.resto-detail {
			h1 {
				position: relative;
				padding-bottom: 30px;
				margin-bottom: 30px;
				&:after {
					content: "";
					position: absolute;
					bottom: 0;
					right: calc(50% - 35px);
					height: 3px;
					width: 75px;
					background: #ebebeb;
				}
			}
		}
		.box-info {
			.box-logo {
				margin: 0 auto 20px;
			}
		}
	}
	.content-deals {
		.box-content {
			.box-image {
				a {
					padding-bottom: 48.34%;
				}
			}
		}
	}
	.content-beauty {
		.beauty-box {
			text-align: center;
			font-size: 0;

			.boxset {
				display: inline-block;
				float: none;
				box-sizing: border-box;
				font-size: 14px;
			}
		}
	}
	.content-rooms {
		&.room-detail {
			h1 {
				margin-bottom: 15px;
				padding-bottom: 5px;
			}
			.sub-title, .subtitle {
				text-align: center;
				text-transform: uppercase;
				font-weight: 400;
				font-size: 16px;
				margin-bottom: 30px;
				padding-top: 25px;
				position: relative;
				&:before {
					content: "";
			    position: absolute;
			    top: 0;
			    right: calc(50% - 35px);
			    height: 3px;
			    width: 75px;
			    background: #ebebeb;
				}
			}
		}
		.margin-blarge {
			p {
				text-align: justify;
			}
		}
	}
	.main-amenities {
		h4 {
			position: relative;
			padding-top: 30px;
			color: $main-color;
			padding-bottom: 20px;
			&:before {
				content: '';
				position: absolute;
				top: 0;
				right: calc(50% - 35px);
				//left: calc(50% - 35px);
				height: 3px;
				width: 75px;
				background: $color-light-grey
			}
		}
		.amenities-box {
			background: $color-light-grey;
			padding: 20px 0;
			margin-bottom: 30px;
		}
	}

	.content-meeting {
		.box-info {
			> .desc {
				p {
					height: 80px;
					min-height: 80px;
				}
			}
		}
		&.meeting-detail {
			.box-proposal {
				margin: 20px 0 30px;
				a {
					width: 30%;
					@media(max-width: 767px){
						width: 70%;
					}
				}
				p {
					font-style: italic;
				}
			}

			.info-room {
				h4 {
					position: relative;
					padding-top: 30px;
					color: $main-color;
					padding-bottom: 20px;
					&:before {
						content: "";
				    position: absolute;
				    top: 0;
				    right: calc(50% - 35px);
				    height: 3px;
				    width: 75px;
				    background: #ebebeb;
					}
				}
			}
		}
	}
	.content-sitemap {
		.item-map > li > a {
			color: $main-color;

			li {
				list-style-type: none;
			}
		}
	}
	.content-contact {
		.contact-address {
			.contact-item {
				.box-icon {
					width: 50px;
					height: 50px;
					overflow: hidden;
					display: inline-block;
					margin: 0 auto;
					.icon-inner {
						display: table;
						text-align: center;
						width: 100%;
						height: 100%;
						table-layout: fixed;
						box-sizing: border-box;
						.icon {
							display: table-cell;
							vertical-align: middle;
						}
					}
				}
				i {
					font-size: 20px;
			    line-height: 20px;
			    vertical-align: middle;
					color: $main-color;
				}
			}
		}
		.contact-form {
			input[type="text"],
			input[type="email"],
			textarea  {
				line-height: 24px;
				width: 100%;
				border: 1px solid #dce0e2;
				padding: 7px 15px;
				font-size: 13px;
				margin-bottom: 10px;
				line-height: 24px;
				-webkit-border-radius: 3px;
				-moz-border-radius: 3px;
				border-radius: 3px;
				min-height: 40px;
			}
			input[type="submit"]{
				width: 100%;
			}
		}
	}

	&.contact-page {
		margin-bottom: 0!important;
		.contact-map {
			margin-top: 30px;
		}
	}
	&.detail-facility {
		margin-bottom: 0!important;
		.facility-detail {
			position: relative;

			.container {
				&.slideshow {
					position: absolute;
					bottom: 13px;
					width: 100%;
					margin: 0 auto;
					background: rgba(0,0,0,.5);

					.slider-nav {
						@media(min-width: 1025px){
							width: 960px;
						}
						width: 90%;
						margin: 5px auto!important;

						.slick-track {
							margin: 0 auto;
						}

						&.global-thumbimg {
							.item {
								padding: 0 2px;
							}
						}
					}
				}
			}
		}
	}

	.content-facility {
		.row {
			text-align: center;
			font-size: 0;
			div[class*='col-'] {
				display: inline-block;
				font-size: 14px;
				float: none;
			}
		}
	}
}

.nbutton {
	border: 1px solid $main-color;
	padding: 10px 20px;
	text-align: center;
	text-transform: uppercase;
	color: $main-color;
	display: inline-block;
	@media(max-width: 767px){
		padding: 10px;
	}
	&:hover {
		color: $color-white;
		background: $main-color;
	}
}

// gallery page landing style

// -----------------------------------------------------------------------------
// Slider
// -----------------------------------------------------------------------------

.owl-carousel {
	position: relative;
	height: 100%;

	// div:not(.owl-controls) {
	// 	height: 100%;
	// }

	.owl-slide {
		display: block;
		width: 100%;
		height: 0;
		padding-bottom: 90vh;
		overflow: hidden;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: cover;
	}

	div.owl--text {
		position: absolute;
		bottom: 4em;
		left: 2em;
		width: 20em;
		height: 8em;
		padding: 1em;
		background: rgba(255, 255, 255, .5);
		border-radius: 4px;
	}

	.owl-nav {
		position: absolute;
		top: 40%;
		left: 20px;
		right: 20px;
		@media(max-width: 767px){
			top: 45%;
		}
		.owl-prev {
			float: left;
		}
		.owl-next {
			float: right;
		}
		i {
			color: $color-white;
			font-size: 40px;
		}
	}
	.deals-slide {
		.owl-prev {
			float: left;
			&:before {
				font-family: 'FontAwesome';
				content: '\f104';
				font-size: 28px;
				color: $color-white;
			}
		}
		.owl-next {
			float: right;
			&:before {
				font-family: 'FontAwesome';
				content: '\f105';
				font-size: 28px;
				color: $color-white;
			}
		}
		i {
			color: $color-white;
			font-size: 40px;
		}
	}
}
.container {
	&.home {
		margin-top: -125px;
    z-index: 999;
    position: relative;
	}
}
#wrapper {
	&.homepage {
		.owl-carousel {
			.owl-slide {
				padding-bottom: calc(100vh - 70px);
				position: relative;
				@media(max-width: 1024px) {
					padding-bottom: 50vh;
				}
				@media(max-width: 767px) {
					padding-bottom: 80vh;
				}
				&:after {
					content: '';
					display: block;
					position: absolute;
					left: 0;
					top: 0;
					height: 100%;
					width: 100%;
					background: rgba(0,0,0,0.7);
				}
				> .container {
					position: relative;
					padding-bottom: calc(100vh - 70px);
					.text-slider {
						color: $color-white;
						z-index: 99;
	    			position: absolute;
						left: 15px;
						bottom: 150px;
						width: 500px;
						max-width: 100%;
						text-align: right;
						h2 {
							font-size: 22px;
						}
						.read-more {
							a {
								display: inline-block;
								background: $main-color;
								color: $color-white;
								font-weight: 500;
								text-transform: uppercase;
								padding: 0 20px;
						    height: 35px;
						    line-height: 35px;
							}
						}
					}
				}
			}
		}
	}
}

// -----------------------------------------------------------------------------
// Footer
// -----------------------------------------------------------------------------
.main-footer {
	margin-top: 50px;

	ul {
		list-style: none;
		padding-left: 0;
	}

	.footer-link {
		background: $color-light-grey;
		padding: 35px 0;
		.row {
			> div {
				@media (max-width: 767px) {
					height: 130px;
				}
				@media (max-width: 360px) {
					height: 165px;
				}
			}
		}
		ul {
			li {
				padding: 2px 0;
				text-transform: uppercase;
				a {
					text-transform: uppercase;
					color: $base-color;
					&:hover {
						color: $main-color;
					}
				}
			}
		}
		@media(min-width: 1025px){
			ul {
				margin-bottom: 0;
				width: 100%;
			}

			li {
				padding: 0 10px;
			}
		}
		@media(max-width: 1024px) {
			ul {
				// text-align: center;
				li {
					// display: inline-block;
					padding: 3px 0;
					text-transform: uppercase;
					@media(max-width: 767px) {
						display: block;
					}
				}
			}
		}
		@media(max-width: 767px){
			padding: 25px 0 10px;
		}
	}

	.footer-info {
		@media(min-width: 768px){
			// line-height: 65px;
		}
		color: $color-white;
		background: $main-color;
		li {
			display: inline-block;
			padding: 0 10px;
			a {
				background: $color-white;
				border-radius: 50%;
				width: 40px;
		    height: 40px;
		    display: block;
				i {
					color: $color-white;
					line-height: 40px;
					text-align: center;
					display: block;
					font-size: 18px;
					margin: 0 auto;
				}
				&.icn-facebook {
					background: #415cb5;
				}
				&.icn-twitter {
					background: #5bbdec;
				}
				&.icn-instagram {
					background: #415cb5;
				}
			}
		}

		.info-socmed {
			@media(max-width: 992px) {
				padding-left: 0;
				padding-right: 0;
				margin: 0px -10px;
				background: $color-light-grey;
			}
			@media(max-width: 767px) {
				margin: 0;
				padding: 15px 0;
			}
			ul {
				margin-bottom: 0;
				margin-left: 0;
				@media(max-width: 1024px) {
					text-align: center;
				}
				@media(max-width: 1024px) {
					li {
						display: inline-block;
					}
				}
			}
		}
	}
}
// FOR SINGLE page
// contact Pages
.page {
	&.contact {
		.outer-map {
			margin-top: 30px;
			height: 300px;
			background: $color-light-grey;
		}
		.outer-address {
			margin-top: 20px;
			text-align: center;
			.social-address {
				margin-top: 20px;
				.row {
					text-align: center;
					> div {
						float: none;
						display: inline-block;
						width: auto;
					}
				}
				.fa {
					font-size: 20px;
					float: left;
					margin-right: 5px;
				}
				span {
					display: inline-block;
					line-height: 21px;
				}
			}
		}
	}
}

// tab singlepage
#tabsinglepage {
	&.outer-tab-link {
		&.about-page, &.career-page {
			.nav-tabs {
				margin: 0 -2.5px;
				border-bottom: 0;
				>li {
					width: 33.333%;
					margin-bottom: 0;
					padding: 2.5px;
					@media (max-width: 767px) {
						width: 100%;
					}
				}
			}
		}
		.nav-tabs {
			margin: 0 -2.5px;
			border-bottom: 0;
			text-align: center;
			font-size: 0;
			>li {
				width: 25%;
				margin-bottom: 0;
				padding: 2.5px;
				float: none;
    		display: inline-block;
				@media (max-width: 767px) {
					width: 33.333%;
				}
				a {
					display: block;
					text-align: center;
					margin-right: 0;
					border: 0;
					border-radius: 0;
					background: $color-very-light-grey;
					border-bottom: 3px solid $color-very-light-grey;
					@media (max-width: 767px) {
						padding: 10px 5px;
					}
					.img-icon {
						display: block;
						text-align: center;
				    height: 40px;
				    position: relative;
				    margin-top: 20px;
						margin-bottom: 20px;
						-webkit-transition: background 0.3s ease;
						-ms-transition: background 0.3s ease;
						-o-transition: background 0.3s ease;
						transition: background 0.3s ease;
						@media (max-width: 767px) {
							margin-top: 0;
							margin-bottom: 0;
						}
						.fa {
							line-height: 70px;
							color: $color-grey;
							-webkit-transition: color 0.3s ease;
							-ms-transition: color 0.3s ease;
							-o-transition: color 0.3s ease;
							transition: color 0.3s ease;
						}
						img {
							position: absolute;
					    max-height: 100%;
					    max-width: 90%;
					    display: block;
					    left: 50%;
					    top: 50%;
					    -webkit-transform: translate(-50%,-50%);
							-ms-transform: translate(-50%,-50%);
							-o-transform: translate(-50%,-50%);
					    transform: translate(-50%,-50%);
							@media (max-width: 767px) {
								max-width: 100%;
								max-height: 75%;
							}
						}
					}
				}
				&:hover, &.active {
					a {
						border-bottom: 3px solid $main-color;
						.fa {
							color: $main-color;
						}
					}
				}
			}
		}
	}
}
#wrapper {
	&.our-product {
		.content-single {
			margin-top: 0 !important;
			.tab-pane {
				padding-top: 60px;
			}
		}
	}
	&.page {
		.hero-image {
			position: relative;
			padding-bottom: 40%;
			background-size: cover !important;
			background-position: center !important;
			overflow: hidden;
			min-height: 350px;
			.overlay {
				position: absolute;
				height: 100%;
				width: 100%;
				background: rgba(0,0,0,0.7);
			}
		}
		.singlepage {
			margin-top: -55px;
			&.career, &.contact, &.termsconditions, &.about {
				margin-top: 0;
			}
		}
		.content-single {
			margin-top: 30px;
			.tab-content {
				h2 {
					text-align: center;
					font-size: 24px;
					margin-bottom: 30px;
					color: $color-blue;
					@media (max-width: 767px) {
						margin-bottom: 15px;
					}
				}
				.panel {
					margin: 20px 0;
					border-radius: 0 !important;
					-webkit-box-shadow: none;
    			box-shadow: none;
					.panel-heading {
						background: $color-light-grey;
						border-color: $color-light-grey;
						border-radius: 0 !important;
						h3 {
							text-align: center;
							font-size: 20px;
						}
					}
					.panel-body {
						@media (min-width: 768px) {
							padding-left: 100px;
							padding-right: 100px;
						}
						ul {
							list-style-type: none;
							padding: 0;
							margin: 0;
							color: #777;
							li {
								position: relative;
								padding-left: 20px;
								line-height: 1.5;
								font-size: 14px;
								margin-bottom: 5px;
								&:before {
									content: '';
									display: block;
									position: absolute;
									width: 10px;
									height: 10px;
									left: 0;
									top: 5px;
									background: $main-color;
									-webkit-border-radius: 100%;
									-ms-border-radius: 100%;
									border-radius: 100%;
								}
							}
						}
					}
				}
				#whoweare {
					.content-desc {
						.text-center {
							img {
								margin: 20px auto;
								max-width: 80%;
							}
						}
					}
				}
				#visionmission {
					.content-desc {
						h5 {
							font-size: 16px;
						}
						ul {
							width: 100%;
							max-width: 600px;
							list-style-type: none;
							margin: 0 auto;
							padding: 0;
							li {
								position: relative;
								padding: 0 0 5px 15px;
								&:before {
									content: '';
									display: block;
									width: 5px;
									height: 5px;
									background: $main-color;
									position: absolute;
									left: 0;
									top: 5px;
									-webkit-border-radius: 100%;
									-ms-border-radius: 100%;
									border-radius: 100%;
								}
							}
						}
					}
				}
				.content-desc {
					margin-bottom: 50px;
					@media (max-width: 767px) {
						margin-bottom: 25px;
					}

					.right-text {
						margin-top: 20px;
						// padding-bottom: 50px;
						p {
							line-height: 1.5;
							text-align: justify;
						}
					}
					.img-visionmission {
						margin: 30px 0;
						img {
							margin: 20px auto;
							max-width: 100%;
						}
					}
				}
				.content-white-single {
					background: $color-white;
					padding: 30px 0;
					h3 {
						text-align: center;
						font-size: 16px;
						color: $color-blue;
						margin: 30px 0 10px 0;
						font-weight: 500 !important;
						@include body-font;
					}
					.list-ota-content {
						margin-bottom: 15px;
						.left-icon {
							float: left;
							height: 40px;
							width: 40px;
							color: $color-white;
							-webkit-border-radius: 100%;
							-ms-border-radius: 100%;
							border-radius: 100%;
							background: $main-color;
							font-size: 18px;
							text-align: center;
							margin-right: 10px;
							overflow: hidden;
							img {
								height: 40px;
								width: 40px;
								-webkit-border-radius: 100%;
								-ms-border-radius: 100%;
								border-radius: 100%;
							}
							i {
								line-height: 40px;
							}
						}
						.right-content {
							// overflow: hidden;
							position: relative;
							height: 40px;
							h4{
								margin-bottom: 0;
								font-size: 14px;
								text-transform: capitalize;
								font-weight: 500 !important;
								@include body-font;
    						line-height: 1.2;
								position: absolute;
								left: 50px;
								top: 50%;
								-webkit-transform: translate(0, -50%);
								-ms-transform: translate(0, -50%);
								-o-transform: translate(0, -50%);
								transform: translate(0, -50%);
								@media (max-width: 420px) {
									font-size: 12px;
									left: 45px;
								}
							}
							p {
								margin: 0;
								color: $color-grey;
								line-height: 1.4;
								@media (min-width: 768px) {
									height: 32px;
									overflow: hidden;
								}
							}
						}
					}
					.list-bookingengine-content {
						margin-bottom: 15px;
						position: relative;
						padding-left: 15px;
						&:before {
							content: '';
							display: inline-block;
							position: absolute;
							height: 8px;
							width: 8px;
							background: $main-color;
							left: 0;
							top: 5px;
							border-radius: 100%;
						}
						h4 {
							margin-bottom: 3px;
							font-size: 16px;
						}
						p {
							margin: 0;
							a {
								color: $main-color;
								font-style: italic;
								font-size: 14px;
							}
						}
					}
					.clients-partners {
						text-align: center;
						font-size: 0;
						.list {
							width: 16.666%;
							padding: 2.5px;
							display: inline-block;
							@media (max-width: 992px) {
								width: 20%;
							}
							@media (max-width: 768px) {
								width: 33.333%;
							}
							@media (max-width: 600px) {
								width: 50%;
							}
							.outer-list {
								position: relative;
								border: 1px solid $color-light-grey;
								height: 100px;
								img {
									max-width: 80%;
									max-height: 90%;
									position: absolute;
									left: 50%;
									top: 50%;
									-webkit-transform: translate(-50%, -50%);
									-ms-transform: translate(-50%, -50%);
									-o-transform: translate(-50%, -50%);
									transform: translate(-50%, -50%);
								}
							}
						}
						> p {
							color: $color-grey;
							font-weight: 500;
							font-size: 14px;
							margin-top: 10px;
						}
						.logo-bottom {
							text-align: center;
							img {
								margin: 10px auto;
								width: 240px;
							}
						}
						ul {
							list-style-type: none;
							margin: 0;
							padding: 0;
							font-size: 12px;
							text-align: left;
							&:after {
								content: '';
								display: table;
								clear: both;
							}
							li {
								float: left;
								width: 25%;
								padding: 0 10px 0 15px;
								position: relative;
								margin-bottom: 10px;
								font-weight: 500;
								@media (max-width: 767px) {
									width: 50%;
								}
								&:before {
									content: '';
									position: absolute;
									display: block;
									height: 6px;
									width: 6px;
									-webkit-border-radius: 100%;
									-ms-border-radius: 100%;
									border-radius: 100%;
									background: $color-blue;
									left: 0;
									top: 5px;
								}
							}
						}
					}
					.outer-table {
						border: 1px solid $color-light-grey;
						@media (max-width: 992px) {
							overflow: auto;
							-webkit-overflow-scolling: touch;
						}
					}
					table {
						width: 100%;
						min-width: 500px;
						td {
							padding: 5px 10px;
						}
						.bg-orange {
							background: $main-color;
							color: $color-white;
							text-align: center;
						}
						.bg-grey {
							background: $color-light-grey;
							color: $color-dark-grey;
							text-align: center;
							font-weight: 500;
						}
						.bg-light-grey {
							background: $color-very-light-grey;
							text-align: center;
						}
					}
					table, tr, td {
						border-collapse: collapse;
						border: 2px solid $color-white;
					}
					.payment-gateway-fee {
						text-align: center;
						text-transform: uppercase;
						padding: 5px 15px;
						background: $main-color;
						color: $color-white;
						border: 2px solid $color-white;
						margin-top: -2px;
					}
				}
				.outer-grid-portfolio {
					.row-one, .row-two, .row-three, .row-four {
						margin: 0 -5px;
						.top, .bottom {
							&:after {
								display: table;
								content: '';
								clear: both;
							}
						}
						.left, .right {
							float: left;
							width: 50%;
							@media (max-width: 768px) {
								width: 100%;
							}
							.item, .item1, .item2 {
								padding: 5px;
								.outer-item {
									position: relative;
									overflow: hidden;
									.overlay {
										opacity: 0;
										position: absolute;
										left: 0;
										top: 0;
										width: 100%;
										height: 100%;
										background: rgba(0,0,0,.7);
										z-index: 0;
										-webkit-transition: all .5s ease;
										-ms-transition: all .5s ease;
										-o-transition: all .5s ease;
										transition: all .5s ease;
										.outer-content {
											position: absolute;
											left: 50%;
											top: 50%;
											text-align: center;
											width: 100%;
											padding: 0 10px;
											-webkit-transform: translate(-50%, -50%);
											-ms-transform: translate(-50%, -50%);
											-o-transform: translate(-50%, -50%);
											transform: translate(-50%, -50%);
											.name {
												color: $color-white;
												text-transform: uppercase;
												font-size: 20px;
												font-weight: 300;
												line-height: 1.2;
											}
											.button {
												a {
													display: inline-block;
													background: $main-color;
													color: $color-white;
													font-weight: 500;
											    font-size: 16px;
											    padding: 5px 15px;
											    margin-top: 10px;
													border-radius: 2px;
												}
											}
										}
									}
									&:hover {
										.overlay {
											opacity: 1;
											z-index: 1;
										}
									}
									img {
										position: absolute;
										height: 100%;
										width: auto;
										max-width: none;
										left: 50%;
										top: 50%;
										-webkit-transform: translate(-50%, -50%);
										-ms-transform: translate(-50%, -50%);
										-o-transform: translate(-50%, -50%);
										transform: translate(-50%, -50%);
									}
								}
							}
							.item1, .item2 {
								float: left;
								width: 50%;
							}
						}
					}
					.row-one {
						.left {
							.top {
								.item {
									.outer-item {
										padding-bottom: 100%;
									}
								}
							}
							.bottom {
								.item {
									.outer-item {
										padding-bottom: 40%;
										img {
											width: 100%;
											height: auto;
											max-height: none;
										}
									}
								}
							}
						}
						.right {
							.item {
								.outer-item {
									padding-bottom: 40%;
									img {
										width: 100%;
										height: auto;
										max-height: none;
									}
								}
							}
							.item1, .item2 {
								.outer-item {
									padding-bottom: 204.5%;
								}
							}
						}
					}
					.row-two {
						.left {
							width: 25%;
							@media (max-width: 768px) {
								width: 100%;
							}
							.item {
								.outer-item {
									padding-bottom: 220%;
								}
							}
						}
						.right {
							width: 75%;
							@media (max-width: 768px) {
								width: 100%;
							}
							.item {
								.outer-item {
									padding-bottom: 35%;
									img {
										width: 100%;
										height: auto;
										max-height: none;
									}
								}
							}
							.item1, .item2 {
								.outer-item {
									padding-bottom: 70.5%;
								}
							}
						}
					}
					.row-three {
						.item {
							.outer-item {
								padding-bottom: 40%;
								img {
									width: 100% !important;
									height: auto !important;
									max-height: none !important;
								}
							}
						}
					}
					.row-four {
						.left {
							.item {
								.outer-item {
									padding-bottom: 44%;
									img {
										width: 100%;
										height: auto;
										max-height: none;
									}
								}
							}
							.item1, .item2 {
								.outer-item {
									padding-bottom: 110%;
								}
							}
						}
						.right {
							.item {
								.outer-item {
									padding-bottom: 100%;
								}
							}
						}
					}
				}
				#indohotels {
					.content-desc {
						img {
							margin-left: -30px;
						}
					}
				}
			}
		}
	}
}
ul {
	&.list-check {
		list-style-type: none;
		margin: 0;
		padding: 0;
		> li {
			position: relative;
			padding: 0 20px;
			margin-bottom: 5px;
			font-size: 14px;
			&:before {
				font-family: 'FontAwesome';
				content: '\f00c';
				position: absolute;
				left: 0;
				top: -2px;
				color: $main-color;
			}
			> ul {
				list-style-type: none;
				margin: 5px 0 0;
				padding: 0;
				li {
					position: relative;
					padding: 0 15px;
					margin-bottom: 2px;
					font-size: 14px;
					color: $color-grey;
					font-style: italic;
					line-height: 1.2;
					&:before {
						content: '-';
						position: absolute;
						left: 0;
						top: 0;
						color: $color-grey;
					}
				}
			}
		}
	}
}
.btn-orange {
	display: inline-block;
	background: $main-color;
	padding: 7px 30px;
	margin-top: 20px;
	color: $color-white;
	letter-spacing: 1px;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 14px;
	&:hover {
		background: $color-blue;
		color: $color-white;
	}
}
.outer-table-package {
	@media (max-width: 992px) {
		overflow: auto;
		-webkit-overflow-scolling: touch;
	}
	table {
		min-width: 350px;
	}
}

// slider slick style on single page
.slick-prev, .slick-next {
	line-height: normal;
	height: auto;
	width: auto;
}
.bigslide {
	.item {
		> span {
			display: block;
			position: relative;
			padding-bottom: 50%;
			min-height: 300px;
			@media(max-width: 1024px){
				padding-bottom: 100vh
			}
			img {
				position: absolute;
				height: auto;
				width: 100%;
				left: 50%;
				top: 50%;
				-webkit-transform: translate(-50%, -50%);
				-ms-transform: translate(-50%, -50%);
				transform: translate(-50%, -50%);
				@media(max-width: 1024px){
					height: 100%;
					object-fit: cover;
				}
			}
		}
	}
}
.global-thumbimg {
	margin-top: 20px !important;
}

// Mobile menu
.mobmenu {
  @media(min-width: 1025px){
    display: none;
  }

  position: absolute;
  top: 6px;
  right: 10px;
  line-height: 20px;

  .user-form {
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
    i {
      font-size: 26px;
      color: $color-dark-grey;
    }
  }
}
.toggleMenu {
  background: transparent;
  border: 1px solid $color-white;
  color: $color-white!important;
  display:  none;
  font-size: 20px;
  padding: 7px 10px;
}
#desknav {
  @media(max-width: 1024px) {
    .nav, .toggleMenu {
      display: none!important;
    }
  }
}
.sidenav {
  @media(min-width:1152px) {
    display: none;
  }

  @media(max-width: 1024px) {
    display: block;
  }

  background: rgba(0,0,0, .7);
  height: 100%;
  overflow-x: hidden;
  left: 0;
  top: 0;
  transition: 0.5s;
  position: fixed;
  width: 0;
  z-index: 999 !important;

  a {
    color: $color-dark-grey!important;
    display: block;
    font-size: 12px;
    padding: 0px;
    text-decoration: none;
    transition: 0.3s;
    line-height: 38px;

    @media(max-width: 767px) {
      line-height: 38px!important;
    }
  }

	li {
		a {
			@media(max-width: 1024px) {
				font-size: 14px;
				border-bottom: 1px solid $color-light-grey;
				text-transform: uppercase;
			}
		}
	}
	li {
		&.has-submenu {
			ul {
				li {
					a {
						@media(max-width: 1024px) {
							line-height: 20px !important;
							padding: 5px 10px !important;
						}
					}
				}
			}
		}
	}

  .closebtn {
    font-size: 30px;
    position: absolute;
    top: 0;
    right: 80px;
    color: $color-dark-grey!important;
		padding: 0 5px;
  }

  .monav {
    > li {
      border-bottom: 1px solid $color-white;
      display: block;
      position: relative;

			&:first-child {
				a {
					border-top: 1px solid $color-light-grey;
				}
			}

      .sub-menu {
				padding-left: 0;
        max-height: 0;
        overflow: hidden;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
      }
      &.shrink {
        > .sub-menu {
          max-height: 200em;
          -webkit-transition-duration: 1s;
          transition-duration: 1s;

          a {
						padding-left: 20px;
						background: rgba(0,0,0,0.03)
          }
        }
      }
      i {
        -webkit-transform: translate(0px, 0px);
        -ms-transform: translate(0px, 0px);
        transform: translate(0px, 0px);
        -webkit-transition: all 0.5s;
        transition: all 0.5s;
        position: absolute;
        top: 0px;
        right: 0px;
        padding: 0 10px;
        line-height: 38px;
        color: $color-dark-grey;
        cursor: pointer;
        height: 38px;
				font-size: 20px;
      }
    }
  }
}
#mobinav {
  .mobinav-inner {
    width: auto;
    background: $color-white;
    height: 100%;
    margin-right: 70px;
    color: $color-dark-grey;
		.monav {
			padding-left: 0;
			padding: 50px 15px;
		}

    &.default {
      .intro {
        background: $color-light-grey;
        padding: 15px 10px;
        text-align: center;
        span {
          text-transform: uppercase;
          display: block;
          margin-bottom: 5px;
        }
        a {
          margin: 0 10px;
          color: $color-white!important;
          border-radius: 3px;
          -webkit-border-radius: 3px;
        }
      }
      .monav {
        a {
          i {
            position: relative;
            color: $color-dark-grey!important;
          }
        }
        .line-top {
          margin-top: 10px;
          padding-top: 10px;
          border-top: 1px solid $color-light-grey;
        }
        .line-bottom {
          margin-bottom: 10px;
          border-bottom: 1px solid $color-light-grey;
        }
      }
    }
    &.login {
      .monav {
        .mobprofile {
          padding: 15px 10px;
          background: $color-light-grey;
          .nav-profile {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

// bootstrap nav menu break point
@media (max-width: 1024px) {
	.navbar-header {
		float: none;
	}
	.navbar-left,.navbar-right {
		float: none !important;
	}
	.navbar-toggle {
		display: block;
		float: left;
		padding: 0;
		border: 0;
		@media (max-width: 1024px) {
			margin-top: 16px;
			margin-bottom: 16px;
		}
		.icon-bar {
			width: 30px;
			height: 3px;
			border-radius: 0;
			background-color: $color-white!important;
			margin-bottom: 2.5px;
			margin-top: 2.5px;
		}
		@media(max-width: 767px){
			margin-left: 15px;
		}
		&:focus {
			background-color: transparent!important;
		}
	}
	.navbar-collapse {
		border-top: 1px solid transparent;
		box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
	}
	.navbar-fixed-top {
		top: 0;
		border-width: 0 0 1px;
	}
	.navbar-collapse.collapse {
		display: none!important;
	}
	.navbar-nav {
		float: none!important;
		margin-top: 7.5px;
	}
	.navbar-nav>li {
		float: none !important;
		display: block !important;
	}
	.navbar-nav>li>a {
		padding-top: 10px;
		padding-bottom: 10px;
	}
	.collapse.in{
		display:block !important;
	}
}
