
// layout.scss

html, body {
	height: 100%;
	margin: 0;
	padding: 0;
}

html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  height: 100%;
  font-size: 62.5%;
}

body {
	font-size: 12px;
	@include body-font;
	font-weight: 400;
	background: $color-white;
	color: $color-main-text;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
	font-family: "Poiret One", cursive, Arial;
	font-weight: bold !important;
}

a {
	color: $main-color;
	&:hover {
		color: $base-color;
		text-decoration: none;
	}
  &:focus {
    outline: 0;
  }
}

/*
* Navbar
*/

header {
	position: fixed;
	width: 100%;
	z-index: 9999;
	top: 0;
}
.navbar-default {
	background: transparent;
	border-radius: 0;
	border: 0;
	margin-bottom: 0;
	height: 65px;
	min-height: 65px;
	padding-top: 0;
	padding-bottom: 0;

	@media(max-width: 1024px) {
		height: auto;
		position: relative;
	}

	.navbar-collapse {
		padding-left: 0px;
		padding-right: 0px;
		float: right;

		@media(min-width: 1025px) {
			margin-left: -15px;
			margin-right: -15px;
		}
	}

	.nav {
		@media(min-width: 1025px){

			> li {
				&.dropdown {
					&:hover {
						.dropdown-menu {
							display: block;
						}
					}
					.dropdown-menu {
						li {
							a {
								padding: 5px 20px;
							}
						}
						@media(min-width: 768px){
							> li {
								> a:hover {
									background: $main-color;
									color: $color-white;
								}
							}
						}
					}
				}

			 	> a {
				 	text-align: center;
			    white-space: nowrap;
					color: $color-white;
					text-transform: uppercase;
					padding-top: 0;
					padding-bottom: 0;
					height: 65px;
					line-height: 65px;
					font-size: 14px;
    			font-weight: 500;
					&:hover, &:focus {
						color: $main-color;
						background: transparent;
					}
		 		}

				&:hover {
					> a {
						// color: $color-white;
					}
				}
			}
		}
	}
}

/*
* Mixin image
*/
@mixin imgbase($position: absolute, $height: 100%, $width: 100%, $top: 50%, $left: 50%, $transform: translate(-50%, -50%), $max-width: inherit!important, $object-fit: cover){
  position: $position;
  height: $height;
  width: $width;
  top: $top;
  left: $left;
  transform: $transform;
  -webkit-transform: $transform;
  -ms-transform: $transform;
  max-width: $max-width;
  object-fit: $object-fit;
}

/*
* Margin
*/
.margin-blarge {
	margin-bottom: 40px;
}

/*
* Button
*/
button {
	background: $base-color;
	color: $color-white;
	&:hover {
		i {
			color: $color-white;
		}
	}
}

.btn-basic {
	padding: 5px 20px;
	border: 1px solid $base-color;
	border-radius: 0;
	font-weight: 300;
	color: $base-color;
	&:hover {
		background: $base-color;
		color: #fff;
		border: 1px solid $base-color;
	}
}

.btn-yellow {
	padding: 5px 20px;
	border: 1px solid $color-yellow;
	background: $color-yellow;
	border-radius: 0;
	font-weight: 300;
	color: $color-white;
	@media(max-width: 767px) {
		border-radius: 3px;
		padding: 10px 20px;
	}
	&:hover {
		background: $base-color;
		color: #fff;
		border: 1px solid $base-color;
	}
}

.form-control {
	&:focus {
		box-shadow: none;
		border-color: #ccc;
	}
}

@media (min-width: 1200px) {
  .container {
    width: 960px;
   }
}
@media (min-width: 992px) {
  .container {
    width: 960px;
   }
}


/*
* Slick slider adjustment
*/
.slider-for, .slideroom-for {
  -webkit-box-shadow: 2px 4px 6px 1px rgba(0,0,0,.2);
  -moz-box-shadow: 2px 4px 6px 1px rgba(0,0,0,.2);
  box-shadow: 2px 2px 6px 1px rgba(0,0,0,.2);
  .slick-arrow {
    color: #fff;
    text-align: center;
    z-index: 99;
    &.slick-prev {
      left: 25px;
    }
    &.slick-next {
      right: 25px;
    }
  }

	button {
		i {
			font-size: 40px;
		}
	}
}
.slick-prev:before,
.slick-next:before {
	display: none;
}

.slideroom-for {
	box-shadow: none;
	@media(min-width: 768px){
		.slick-arrow {
			display: none!important;
		}
	}

	@media(max-width: 767px){
		.slick-arrow {
			top: 25%;
		}
	}
}

.white-popup {
  position: relative;
  background: $color-white;
  padding: 25px;
  width: auto;
  max-width: 600px;
  margin: 0 auto;
	&.homepop {
		padding: 0;
		.intro-contentmb {
			img {
				width: 100%;
			}
		}
		.mfp-close {
			right: -10px;
			top: -40px;
			color: $color-white;
		}
	}
}

.mfp-zoom-in {
  /* start state */
  /* animate in */
  /* animate out */
}
.mfp-zoom-in .mfp-with-anim {
  opacity: 0;
  transition: all 0.2s ease-in-out;
  transform: scale(0.8);
}
.mfp-zoom-in.mfp-bg {
  opacity: 0;
  transition: all 0.3s ease-out;
}
.mfp-zoom-in.mfp-ready .mfp-with-anim {
  opacity: 1;
  transform: scale(1);
}
.mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}
.mfp-zoom-in.mfp-removing .mfp-with-anim {
  transform: scale(0.8);
  opacity: 0;
}
.mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

@media(max-width: 1024px){
	.top-logo {
		position: absolute;
    top: 0px;
    left: 50%;
		-webkit-transform: translate(-50%, 0);
		-ms-transform: translate(-50%, 0);
		-o-transform: translate(-50%, 0);
		transform: translate(-50%, 0);
	}
	.section-top.scrolled {
		.navbar-toggle {
			.icon-bar {
				background: $main-color!important;
			}
		}
		.navbar-default {
			min-height: 60px;
		}
	}
}
